$brand-color: #F77307;

.not-found {
    text-align: center;
    padding: 50px;
    color: $brand-color;
    border: 1px solid $brand-color;
    padding: 10px;
    max-width: 500px;
    margin: 20px auto;
}