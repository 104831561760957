$brand-color: #F77307;

.gallery{
    padding: 0 30px;
    .gallery-item {
        padding: 25px;
        border: 1px solid $brand-color;
        border-radius: 2%;
        position: relative;
        margin: 10px 10px;
        .gallery-img {
            width: 100%;
            -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
        }
        .gallery-overlay {
            background: rgba(52, 58, 64, 0.7);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: -webkit-flex;
            display: -ms-flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
                -ms-flex-pack: center;
                    justify-content: center;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all 0.2s linear 0s;
            transition: all 0.2s linear 0s;
            .gallery-icon {
                font-size: 2.5rem;
                font-weight: bold;
                color: #fff;
            }
        }
        &:hover .gallery-overlay {
            visibility: visible;
            opacity: 1;
        }
    }
}

.zoom {
    padding: 50px;
    transition: transform .2s;
    &:hover {
        transform: scale(1.01);
    }
}

.gallery-star{
    color: $brand-color;
    position: absolute;
    bottom: 25px;
    right: 30px;
    &:hover{
      color: #F50;
    }
}

.share-img{
    transform: scale(0.9);
    &:hover{ 
        filter: brightness(0.8) saturate(2.2) hue-rotate(0deg) invert(0.1);
    }
}

.gallery-share{
    position: absolute;
    bottom: 30px;
    left: 30px;
    &:hover{
        color: #F50 !important;
        fill: #F50 !important;
    }
}

.gallery-share-icons{
    position: absolute;
    bottom: 18px;
    left: 15px;
}

.gallery-img-container:before{
    content: '';
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 100%);
    border-radius: 2%;
}

.container-fluid{
    margin-top: 90px !important;
}