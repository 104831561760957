$brand-color: #F77307;
$body-color: #343a40;
$navbar-color: rgba(33,37,41,1);

.navbar {
    background: $navbar-color;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 1500px;
}

.navbar-logo {
    color: $brand-color;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 30vw;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 1rem;
}

.nav-item {
    height: 60px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    height: 100%;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: $navbar-color;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        margin-top: -10px;
        align-items: center;
        justify-content: center;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .nav-links {
        &:hover{
            color: #F77307;
            border-radius: 0;
            cursor: pointer !important;
        }
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(5%, 45%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
}

.profile-button{
    max-width: 40px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    &:hover{
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
    }
}

.page-wrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.blur-it {
    filter: blur(4px);
}

.modal-wrapper {
    z-index: 9;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(41, 41, 41, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    &.open {
        z-index: 9;
        opacity: 1;
        visibility: visible;
        .modal {
            margin-top: -200px;
            opacity: 1;
        }
    }
}

.modal{
    width: 500px;
    height: 325px;
    display: block;
    margin: 50% 0 0 -250px;
    position: relative;
    top: 50%;
    left: 50%;
    background: white;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    position: absolute;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.btn-close {
    font-size: 28px;
    float: right;
}

.content {
    text-align: center;
    padding: 10%;
}

.search-custom{
    color: white;
    max-width: 70px;
    &:hover{
        color: $brand-color;
        transform: scale(1.2);
        transition: all 0.2s ease-in-out;
    }
}

.brand-color{
    color: $brand-color;
}

.dropbtn {
    color: white;
    padding: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: $navbar-color;
    min-width: 160px;
    z-index: 1;
    font-size: 18px;
    text-align: left;
}

.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {display: block;}

@media screen and (max-width: 768px) {
    .modal {
        width: 350px;
        height: 280px;
        margin: 5% 0 0 -180px !important;
    }
    .input-search{
        width: 90% !important;
    }
}