$brand-color: #F77307;

footer {
    padding: 50px 0;
    background-color: rgb(33, 37, 41) !important;
}

footer h5 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

footer a {
    color: $brand-color !important;
}

footer a:hover  {
    color: #F50 !important;
}

footer ul.list-unstyled {
    margin: 0;
    padding: 0;
}

footer ul.list-unstyled li a {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}

footer ul.list-unstyled li a:hover {
    text-decoration: none;
    color: #f8b739;
}

footer address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.5;
}

footer p.mb-0 {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
}

footer a {
    color: #f8b739;
    text-decoration: none;
}

footer a:hover {
    color: #fff;
    text-decoration: none;
}