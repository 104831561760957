$brand-color: #F77307;

.container {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
}
  
.radio-buttons {
    display: inline-block;
    margin-bottom: 1rem;
}
  
label {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center;
    position: relative;
}
  
input[type="radio"] {
    margin-left: 0.5rem;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
  
input[type="radio"] + span {
    content: "";
    vertical-align: middle;
    // appear on the left
    position: absolute;
    left: -40px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    margin-right: 0.5rem;
}
  
input[type="radio"]:checked + span {
    background-color: white;
}
  
input[type="radio"]:focus + span {
    outline: none;
    border: 2px solid $brand-color;
}
  
input[type="radio"] + span::before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}
  
input[type="radio"]:checked + span::before {
    background-color: $brand-color;
}

.form-select {
    color: $brand-color;
    border-color: $brand-color;
    background-color: #343a40;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23F77307' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    &:focus{
        outline: 0;
        box-shadow: 0 0 0 0.1rem $brand-color !important;
        border-color: $brand-color !important;
    }
}