$brand-color: #F77307;
$body-color: #343a40;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

html{
  overflow-x: hidden !important;
  height: 100%;
  max-width: 100%;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

p{
  color: #fff;
}

ul{
  color: #fff;
}

a{
  color: #fff;
  text-decoration: none !important;
  &:hover {
    color: $brand-color;
    text-decoration: none;
  }
}

body {
  /* background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%); */
  /* background: linear-gradient(0deg, rgba(0,0,0,1), rgba(247, 115, 7, 1)); */
  /* background: linear-gradient(0deg, rgb(199, 0, 23), rgba(0,0,0,1) 60%);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-color: $body-color;
  overflow-x: hidden !important;
}

h1, h2, h3, h4, h5{
  color: #fff;
}

label{
  color: $brand-color;
}

hr{
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  padding: 30px;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  border: 1px solid $brand-color;
  background-color: $body-color;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  margin-top: 2% !important;
  color: #fff !important;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.1rem $brand-color !important;
  }
  &:hover {
    background-color: $brand-color;
    color: #242424;
    transition: 500ms;
  }
  &:active {
    transform: scale(0.98);
  }
  &-block {
    display: block;
    width: 100%;
  }
}

.hero-btns {
  display: flex !important;
}

.highlighted_btn{
  background-color: $brand-color;
}

.text-center {
  text-align: center !important;
}

.text-light {
  color: #f8f9fa !important;
}

.ingredient-list {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ingredient-list li {
  width: 100%;
  text-align: left;
}

.detail-image{
  max-width: 500px;
  max-height: 500px;
}

.min-width{
  min-width: 300px;
}

.cocktail-name{
  text-align: center;
  color: $brand-color;
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: 700 1.7rem/1.7rem 'Roboto', sans-serif;
}

.invis {
  display: none;
}

.full-center{
  text-align: center;
  align-items: center !important;
  justify-content: center !important;
}

.echo-romeo{
  color: $brand-color;
  float: right;
}

.loading{
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.tt {
  display:inline-block;
  position:relative;
  border-bottom:1px dotted gray;
  text-align:left;
}

.tt .top {
  min-width:180px; 
  top:-20px;
  left:50%;
  transform:translate(-20%, -100%);
  padding:10px 20px;
  background-color:$brand-color;
  font-size:13px;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  display:none;
}

.tt:hover .top {
  display:block;
}

.tt .top i {
  position:absolute;
  top:100%;
  left:20%;
  margin-left:-12px;
  width:24px;
  height:12px;
  overflow:hidden;
}

.tt .top i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:$brand-color;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}