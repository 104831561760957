$brand-color: #F77307;

.search-box {
    width: fit-content;
    height: fit-content;
    position: fixed;
}

.search-bar {
    align-items: center !important;
    margin-top: 20px;

    .input-search {
        align-items: center !important;
        width: 390px;
        border-radius: 10px;
        background-color: $brand-color;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);

        &::placeholder {
            color: #fff;
            font-size: 12px;
            letter-spacing: 2px;
            font-weight: 10;
        }
    }

    .autocom-box {
        background-color: white;
        border-radius: 10px;
        padding: 0px;
        max-height: 220px;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        opacity: 0;
        pointer-events: none;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            list-style: none;
            padding: 8px 12px;
            display: none;
            width: 100%;
            cursor: default;
            border-radius: 3px;

            &:hover {
                background-color: $brand-color;
                color: white;
            }
        }
    }

    &.active {
        .autocom-box {
            padding: 10px 8px;
            opacity: 1;
            pointer-events: painted;

            li {
                display: block;
            }
        }
    }
}

.input-search {
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    background-color: $brand-color;
    color: #fff;
}